export const API_VERSION = "v1.0";
export const SOCKET_URL = "ws://localhost:5000/messages";
export const COM_PORT = "COM_PORT";
export const IP_ADDRESS = "IP_ADDRESS";
export const PORT = "PORT";

export const ARENA_TOKEN = ``;
export const SUPER_ADMIN_TOKEN = ``;
export const QFX_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjBmZDc1OWM2LTczMTYtNDdlZi1iZmYyLTg3ZWYwNTYxYWUxMCIsImlhdCI6MTY3MjEzNzgwOX0.niE2hcIACf-Fu_glcbh8Vtm5f83WUjygGG9GbJTw4-o`;
export const TOKEN = QFX_TOKEN;

const HOST_URL = `qfxstg2-kiosk.brij.tech`;

const LIVEBASEURL = `https://qfxstg2-api.brij.tech`;
const DEVBASEURL = ``;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;

export const BASEURL =
  window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;

//  mode should be an integer
// 1- only ticket
// 2- only fnb
// 3 - both

export const mode = 3;
